import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "./Loading"

const addRequestMutation = gql`
    mutation ($file: Upload!) {
        addRequest(file: $file) {
            reqNum
        }
    }
`

const UploadRequest = () => {
    const { keycloak, initialized } = useKeycloak()

    const [addRequest, { data, loading, error }] = useMutation(addRequestMutation, {
        fetchPolicy: "no-cache", // Doesn't check cache before making a network request
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })
    const [file, setFile] = React.useState("")

    const handleOnChange = e => {
        setFile(e.target.files[0])
    }
    const handleSubmit = e => {
        e.preventDefault()

        addRequest({ variables: { file: file } })
            .then(() => {})
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            {/* <h4>Загрузите новый запрос</h4> */}
            <p>
                (1) Выберите файл .XLSX, содержащий на первом листе 2 колонки PN и количество (файл можно создать по{" "}
                <a href="/exampleReq.xlsx" title="Шаблон запроса">
                    шаблону
                </a>
                )
            </p>
            <form>
                <label htmlFor="files" className="btn btn-info">
                    Выберите файл
                </label>
                <input
                    id="files"
                    type="file"
                    accept=".xlsx"
                    className="input-file"
                    onChange={e => handleOnChange(e)}
                ></input>
                {file && <p>{file?.name}</p>}
                <p>(2) Загрузите выбранный файл</p>
                <button type="submit" onClick={e => handleSubmit(e)} className="btn btn-primary">
                    Загрузите файл
                </button>
            </form>
            <br />
            {loading && <Loading />}
            {error && <p>Error: {error.message}</p>}
            {data && <p>Файл был успешно загружен {data.addRequest.reqNum}</p>}
        </div>
    )
}

export default UploadRequest
