import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearReq, removeItem, setAmount } from "../features/req/reqSlice"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../components/Loading"
import { useKeycloak } from "@react-keycloak/web"
import { Typography, Stack, TextField, Button } from "@mui/material"
import StyledTable from "./StyledTable"
import { rowActionCell } from "../utils/utils"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"

const addReqMutation = gql`
    mutation ($positions: [requestInputType!]!) {
        addReq(positions: $positions) {
            num_req
        }
    }
`

const CurrentRequest = () => {
    const dispatch = useDispatch()
    const { reqItems, total, amount } = useSelector(store => store.req)

    const { keycloak, initialized } = useKeycloak()

    const [addReq, { data: req_data_list, loading: req_loading_list, error: req_error_list }] = useMutation(
        addReqMutation,
        {
            fetchPolicy: "no-cache", // Doesn't check cache before making a network request
            context: {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            },
        }
    )

    const handleReq = e => {
        e.preventDefault()
        const positions = reqItems.map(item => {
            return { id: item.id, amount: parseInt(item.amount) }
        })
        addReq({ variables: { positions } })
            .then(() => {})
            .catch(err => {
                console.log(err)
            })

        dispatch(clearReq())
    }
    const cachedReqItems = React.useMemo(() => {
        return reqItems
    }, [reqItems])

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography sx={{ width: "150px", minWidth: "150px" }}>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN
                    </Typography>
                ),
                accessor: "pn",
                Cell: ({ row: { original }, value }) => {
                    return <Typography sx={{ width: "150px", minWidth: "150px" }}>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Мин. / Склад
                    </Typography>
                ),
                accessor: "min_amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return (
                        <>
                            <Typography align="right">Мин.: {value}</Typography>
                            <Typography align="right">Свободно: {original.amount_free}</Typography>
                            <Typography align="right">Резерв: {original.amount_reserved}</Typography>
                        </>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Количество
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value: initialValue }) => {
                    const [value, setValue] = React.useState(initialValue)

                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <TextField
                                id="amount-req"
                                variant="outlined"
                                size="small"
                                type="number"
                                value={value}
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "right" },
                                    },
                                }}
                                onChange={e => {
                                    setValue(e.target.value)
                                }}
                                onBlur={() => {
                                    dispatch(setAmount({ id: original.id, amount: value }))
                                }}
                            />
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм.
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                disableGroupBy: true,
            },
        ],
        []
    )

    const removeItemFromRequest = original => {
        dispatch(removeItem({ id: original.id }))
    }

    return (
        <>
            {/* <Typography variant="h4">Текущий запрос</Typography> */}
            <StyledTable
                columns={columns}
                data={cachedReqItems}
                onRowClick={(event, rowData) => {}}
                hooks={hooks => {
                    hooks.visibleColumns.push(columns => [
                        ...columns,
                        rowActionCell({
                            IconComponent: DeleteOutlineOutlinedIcon,
                            title: "Удаление позиции из запроса",
                            question: "Вы подтверждаете удаление позиции из формируемого запроса?",
                            dispatchAction: removeItemFromRequest,
                        }),
                    ])
                }}
                actions={[]}
                globalSearch={false}
            />
            <Stack direction="row">
                <Button onClick={handleReq}>Отправить запрос</Button>
                <Button
                    onClick={() => {
                        dispatch(clearReq())
                    }}
                >
                    Очистить список
                </Button>
            </Stack>
            {req_loading_list && <Loading />}
            {req_error_list && <p>Error: {req_error_list.message}</p>}
            {req_data_list && <p>Запрос был успешно сформирован: {req_data_list.addReq.num_req}</p>}
        </>
    )
}

export default CurrentRequest
