import React from "react"
import { useMemo } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "./Loading"
import { addItem } from "../features/order/orderSlice"
import { useDispatch } from "react-redux"
import StyledTable from "../components/StyledTable"
import { Checkbox, Stack, Typography, Box, Chip, IconButton, Snackbar, Alert } from "@mui/material"
import { getToggleAllRowsSelectedProps } from "../utils/utils"
import SelectedRowsDispatchButton from "./SelectedRowsDispatchButton"
import { hideIfRepeat } from "../utils/utils"
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined"

const allRequestQuery = gql`
    query allRequestQuery($pn: String, $status: String) {
        allRequest(filter: { pn: { eq: $pn }, status: { eq: $status } }) {
            nodes {
                id
                date
                num_req
                status
                val
                items {
                    pos
                    synonym
                    line
                    pn
                    amount
                    pcs
                    offered_pn
                    offered_amount
                    offered_pcs
                    delivery_date
                    line_status
                    price
                    sum
                }
            }
        }
    }
`

const gqlUpdateRequest = gql`
    mutation gqlUpdateRequest($id: String!, $operation: String!) {
        updateRequest(id: $id, operation: $operation) {
            num_req
        }
    }
`

const RequestHistory = ({ pn, status = "", showHeader = false }) => {
    const dispatch = useDispatch()
    const { keycloak } = useKeycloak()
    const { loading, error, data, refetch } = useQuery(allRequestQuery, {
        fetchPolicy: "network-only", // Doesn't check cache before making a network request
        variables: { pn, status },
        context: {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            },
        },
    })

    const [updateRequest, { data: data_upd, loading: loading_upd, error: error_upd, reset }] = useMutation(
        gqlUpdateRequest,
        {
            fetchPolicy: "no-cache", // Doesn't check cache before making a network request
            context: {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            },
        }
    )

    const [state, setState] = React.useState({ open: false, message: "", severity: "info" })
    const { open, message, severity } = state

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setState({ ...state, open: false })
    }

    React.useEffect(() => {
        if (data_upd) {
            setState(previousState => {
                return {
                    ...previousState,
                    open: true,
                    message: `Операция успешно выполнена ${data_upd.updateRequest.num_req}`,
                    severity: "success",
                }
            })
        }
        if (error_upd) {
            setState(previousState => {
                return {
                    ...previousState,
                    open: true,
                    message: `Ошибка при выполнении операции ${error_upd}`,
                    severity: "error",
                }
            })
        }
        reset()
        refetch()
    }, [data_upd, error_upd, reset, refetch])

    const handleRequestArchiving = React.useCallback(
        (e, id) => {
            e.preventDefault()

            updateRequest({ variables: { id, operation: "ПомещениеЗапросаВАрхив" } })
        },
        [updateRequest]
    )
    const cachedData = useMemo(() => {
        if (loading || error) return null

        const nodes = []

        data.allRequest.nodes.forEach(o =>
            o.items.forEach(item => {
                nodes.push({
                    id: o.id,
                    date: new Date(o.date).toLocaleDateString(),
                    num_req: o.num_req,
                    status: o.status,
                    pos: item.pos,
                    synonym: item.synonym,
                    line: item.line,
                    pn: item.pn,
                    amount: item.amount,
                    pcs: item.pcs,
                    price: item.price,
                    val: o.val,
                    sum: item.sum,
                    delivery_date: item.delivery_date,
                    line_status: item.line_status,

                    offered_pn: item.offered_pn,
                    offered_amount: item.offered_amount,
                    offered_pcs: item.offered_pcs,
                })
            })
        )

        return nodes
    }, [data, loading, error])

    const columns = useMemo(
        () => [
            {
                id: "select",
                disableSortBy: true,
                Header: ({ getToggleAllRowsSelectedProps }) => {
                    return <Checkbox {...getToggleAllRowsSelectedProps()} />
                },
                Cell: ({ row }) => {
                    return <Checkbox {...row.getToggleRowSelectedProps()} />
                },
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Запрос
                    </Typography>
                ),
                accessor: "num_req", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row, flatRows }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack
                                spacing={1}
                                sx={() => hideIfRepeat(flatRows.indexOf(row), value, flatRows, "num_req")}
                            >
                                <Typography>{value}</Typography>
                                <Typography>{original.date}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <Chip label={original.status} color="primary" variant="outlined" size="small" />
                                    {original.status === "Обработан" && (
                                        <>
                                            <IconButton
                                                onClick={e => {
                                                    handleRequestArchiving(e, original.id)
                                                }}
                                            >
                                                <ArchiveOutlinedIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        )
                    } else {
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Typography>{row.subRows[0].original.date}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <Chip
                                        label={row.subRows[0].original.status}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                    />
                                    {row.subRows[0].original.status === "Обработан" && (
                                        <>
                                            <IconButton
                                                onClick={e => {
                                                    handleRequestArchiving(e, row.subRows[0].original.id)
                                                }}
                                            >
                                                <ArchiveOutlinedIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        )
                    }
                },
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Линия
                    </Typography>
                ),
                accessor: "line", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Typography>{original.synonym}</Typography>
                            </Stack>
                        )
                    }
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN (запрошено)
                    </Typography>
                ),
                accessor: "pn",
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Кол. (запрошено)
                    </Typography>
                ),
                accessor: "amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <Typography>{value}</Typography>
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм. (запрошено)
                    </Typography>
                ),
                accessor: "pcs",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        PN (предложено)
                    </Typography>
                ),
                accessor: "offered_pn",
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Кол. (предложено)
                    </Typography>
                ),
                accessor: "offered_amount",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return (
                        <Stack direction="row" justifyContent="flex-end">
                            <Typography>{value}</Typography>
                        </Stack>
                    )
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Ед. изм. (предложено)
                    </Typography>
                ),
                accessor: "offered_pcs",
                disableSortBy: true,
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                disableGroupBy: true,
            },

            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Мин.
                    </Typography>
                ),
                disableSortBy: true,
                accessor: "min_amount",
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Цена
                    </Typography>
                ),
                accessor: "price", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Валюта
                    </Typography>
                ),
                accessor: "val", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography>{value}</Typography>
                },
                aggregate: leafValues => leafValues[0],
                Aggregated: ({ value }) => {
                    return <Typography>{value}</Typography>
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Сумма
                    </Typography>
                ),
                accessor: "sum", // accessor is the "key" in the data
                Cell: ({ row: { original }, value }) => {
                    return <Typography align="right">{value}</Typography>
                },
                aggregate: "sum",
                Aggregated: ({ value }) => <Typography align="right">{value}</Typography>,
                disableSortBy: true,
                alignHeader: "flex-end",
                disableGroupBy: true,
            },
            {
                Header: () => (
                    <Typography sx={{ fontWeight: "bold" }} noWrap>
                        Поставка
                    </Typography>
                ),
                accessor: "delivery_date", // accessor is the "key" in the data
                Cell: ({ row: { original }, value, row }) => {
                    if (!row.isGrouped) {
                        return (
                            <Stack spacing={1}>
                                <Typography>{value}</Typography>
                                <Chip label={original.line_status} color="primary" variant="outlined" size="small" />
                            </Stack>
                        )
                    }
                },
                disableSortBy: true,
                disableGroupBy: true,
            },
        ],
        [handleRequestArchiving]
    )

    const addItemToOrder = ({ original }) =>
        dispatch(
            addItem({
                id: original.id,
                line: original.line,
                pos: original.pos,
                pn: original.offered_pn,
                amount: original.offered_amount,
                pcs: original.offered_pcs,
                price: original.price,
                val: original.val,
                sum: original.sum,

                delivery_date: original.delivery_date,
            })
        )

    return (
        <>
            {showHeader && <Typography variant="h4">История запросов</Typography>}
            {loading && <Loading />}
            {error && <Typography>{`Error: ${error.message}`}</Typography>}
            {cachedData && (
                <Box>
                    <StyledTable
                        columns={columns}
                        data={cachedData}
                        onRowClick={(event, rowData) => {}}
                        hooks={hooks => {
                            hooks.getToggleAllRowsSelectedProps = [getToggleAllRowsSelectedProps]
                        }}
                        actions={[
                            <SelectedRowsDispatchButton
                                title="Добавить в заказ"
                                msgWarning="Отсутствуют позиции для добавления в заказ"
                                msgSuccess="Позиции были успешно добавлены в заказ"
                                dispatchAction={addItemToOrder}
                            />,
                        ]}
                        cellProps={() => ({ sx: { verticalAlign: "top" } })}
                    />
                </Box>
            )}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert severity={severity} onClose={handleCloseSnackBar} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default RequestHistory
